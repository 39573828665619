import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { size } from 'lodash';

import * as constants from "./constants";

export class FileUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: {
                name: props.defaultFile
            },
            isFileUploaded: props.defaultFile ? true : false,
            IsFileAlreadySubmitted: props.defaultFile ? true : false,
            isFileUploading:false,
            fileSizeError: false,
            uploadedFileGuid: "",
            uploadedFileDbId: props.fileDbId ||0,
            //maxAllowableContentLength:406000000,
            //maxAllowableContentLengthKB: 406000,
            isReviseUrl: /reviseproposal/g.test(window.location.href.toLowerCase()),
            showDownloadProgress: false
        };

        // this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        label: PropTypes.string.isRequired,
        accept: PropTypes.string.isRequired,
        errorMessage: PropTypes.string.isRequired,
        dynamicClass: PropTypes.string.isRequired
    }

    componentDidUpdate(prevProps) {
        if (this.props.defaultFile !== prevProps.defaultFile) {
            this.setState({ selectedFile: { name: this.props.defaultFile }});
        }
    }
    onFileChange = async event => {

        const selectedFile = event.target.files[0];
        this.setState({ isFileUploading: true });
        const totalUploadedFileSize = this.props.totalUploadedFileSize;

        if (this.state.isFileUploaded ) {
            //if (this.state.uploadedFileDbId === 0) {
            //    await this.props.fileDelete(this.state.uploadedFileGuid, this.props.id, true);
            //} else {
            //    await this.props.fileDelete(this.state.uploadedFileDbId, this.props.id, true);
            //}
            const deleteResult = await this.props.fileDelete(this.state.uploadedFileDbId === 0 ? this.state.uploadedFileGuid : this.state.uploadedFileDbId, this.props.id, true);
            //const deleteResultReviseProposal = await this.props.fileDelete(this.state.uploadedFileDbId, this.props.id, true);
        } 
    
        
        const uploadResult = await this.props.fileUpload(selectedFile, this.props.id, this.state.uploadedFileDbId);
        

        if (uploadResult !== constants.Error && uploadResult !== constants.NotAllowed && uploadResult !== constants.MaxContentSizeLimitExceeded) {
            this.setState({ selectedFile, isFileUploaded: true, fileSizeError: false, IsFileAlreadySubmitted: false, isFileUploading:false, uploadedFileGuid: uploadResult});
        }

        if (uploadResult === constants.Error || uploadResult === constants.NotAllowed) {
            this.setState({ selectedFile: { name: '' }, isFileUploaded: false, isFileUploading: false, uploadedFileGuid: ""});
        }

        if (uploadResult === constants.MaxContentSizeLimitExceeded) {
            this.setState({ selectedFile: { name: '' }, isFileUploaded: false, isFileUploading: false, fileSizeError: true, uploadedFileGuid: "" });
        }

    };

    onFileDelete =async () => {

        const deleteResult = await this.props.fileDelete(this.state.uploadedFileDbId === 0 ? this.state.uploadedFileGuid : this.state.uploadedFileDbId, this.props.id, false) ;
        if (deleteResult) {
            this.setState({ selectedFile: { name: this.props.defaultFile }, isFileUploaded: this.props.defaultFile ? true : false, IsFileAlreadySubmitted: this.props.defaultFile ? true : false, uploadedFileGuid:""});
        }
        
    };

    onFileDownload = async () => {
        //console.log(this.state.uploadedFileDbId);
        //console.log(this.props.defaultFile)
        this.setState({ showDownloadProgress: true });
        await this.props.fileDownload(this.state.uploadedFileDbId, this.props.defaultFile);
        this.setState({ showDownloadProgress: false });
    };

    className = (e) => {
        return `${e} actual-btn`;
    }

    isDownloadAllowed() {
        const props = this.props;
        return (props.isDownloadAllowed == undefined || props.isDownloadAllowed == null) ? true : props.isDownloadAllowed;
    }

    renderDownloadButton() {
        const state = this.state;
        const isDownloadAllowed = this.isDownloadAllowed();
        const displayDownloadButton = state.isReviseUrl && state.isFileUploaded && state.IsFileAlreadySubmitted && isDownloadAllowed;

        if (displayDownloadButton)
            return <button className="MuiButtonBase-root MuiButton-root MuiButton-contained light-btn-primary" tabIndex="0" type="button" onClick={
this.onFileDownload}>
                       <span className="MuiButton-label">
                           <img src="images/download-blue.svg" alt="download-file"/>Download
                       </span>
                       <span className="MuiTouchRipple-root"></span>
                   </button>;
        else
        return <React.Fragment/>;
    }

    render() {
        var deleteButton = (
            <button className="MuiButtonBase-root MuiButton-root MuiButton-contained warning-btn" tabIndex="0" type="button" onClick={this.onFileDelete}>
                <span className="MuiButton-label">
                    <img src="images/trash-brown.svg" alt="cancel-upload" />Cancel
                </span>
                <span className="MuiTouchRipple-root"></span>
            </button>);

        return (
            <div className="outer-link">
                <div className="document-details">
                    <div className="document-container">
                        <div className="document-name">
                          <span className="name-req">
                            {this.props.label}
                            {!(this.props.errorMessage.length > 0) && this.props.required && <span className="document-requirement"> Required document</span>}
                          </span>
                          <span className="warning-pill-container">
                            {this.state.uploadedFileGuid.length > 0 && this.state.isReviseUrl  && <small className="text-warning pending-submission">Updated - Pending Submission</small>}
                            {this.props.errorMessage.length > 0 && this.props.required && <small className="text-danger">{this.props.errorMessage}</small>}
                            {this.props.errorMessage.length > 0 && !this.props.required && <small className="text-warning">{this.props.errorMessage}</small>}
                          </span>
                        </div>
                        {!this.state.fileSizeError && (<div className="document-file">{this.state.isFileUploading ? "Uploading..." :this.state.selectedFile.name}</div>)}
                        {this.state.fileSizeError && this.props.required && (<div className="document-file text-danger">Total uploaded file size exceeded allowable limit of {Math.round((Math.round(constants.MaxContentSizeAllowed / 1024) / 1024) * 100) / 100} MB</div>)}
                        {this.state.fileSizeError && !this.props.required && (<div className="document-file text-warning">Total uploaded file size exceeded allowable limit of {Math.round((Math.round(constants.MaxContentSizeAllowed / 1024) / 1024) * 100) / 100} MB</div>)}
                    </div>

                </div>
                <div className="material-actions">
                    {this.state.showDownloadProgress && <button className="MuiButtonBase-root MuiButton-root MuiButton-contained light-btn-primary revise-download" tabindex="0" type="button"><span>Downloading ...</span><span className="loader small-loader"></span></button>}
                    {!this.state.showDownloadProgress ? this.renderDownloadButton() : ''}
                    {this.state.isFileUploaded && !this.state.IsFileAlreadySubmitted ? deleteButton : ''}
                    {/* <button className={"MuiButtonBase-root MuiButton-root MuiButton-contained " + (this.state.isFileUploaded ? "light-btn-primary" : "btn-primary")} tabIndex="0" type="file">
                        <span className="MuiButton-label">
                            <input
                                type="file"
                                id={this.props.id}
                                name={this.props.id}
                                accept={this.props.accept}
                                hidden
                                onChange={this.onFileChange}
                                onClick={(event) => {event.target.value=null}}/>
                            <span className="MuiButton-label">
                                <label htmlFor={this.props.id} className="submit-proposal-button-label">
                                  <img src={this.state.isFileUploaded ? "images/upload-blue.svg" : "images/upload-white.svg"}/>{this.state.isFileUploaded ? "Replace" : "Upload"}
                              </label>
                            </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                    </button> */}

                    <input
                      type="file"
                      id={this.props.id}
                      name={this.props.id}
                      accept={this.props.accept}
                      hidden
                      onChange={this.onFileChange}
                      onClick={(event) => {event.target.value=null}}/>
                    <label htmlFor={this.props.id} className={"MuiButtonBase-root MuiButton-root MuiButton-contained " + (this.state.isFileUploaded ? "light-btn-primary" : "btn-primary")} tabIndex="0" type="file">
                <img src={this.state.isFileUploaded ? "images/upload-blue.svg" : "images/upload-white.svg"} alt={this.state.isFileUploaded ? "file-uploaded" : "upload-file"}/>{this.state.isFileUploaded ? "Replace" : "Upload"}
                    </label>
                </div>
                <div className="chevron-right">
                    <img src="images/banner-arrow.svg" alt="icon-banner"/>
                </div>
            </div>
        );
    }
}

export default FileUpload;
import React, { Component } from 'react';
import Footer from "./Footer";
import Paper from '@material-ui/core/Paper';

class HowToSubmit extends Component {
    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content">
                        <div className="container-fluid">
                            <div className="section-heading">How To Submit:</div>
                            <Paper className="content-container">
                            <ol>
                                <li>Log in to the NIAID e-BidBoard.</li>
                                    <li>Click <strong>Submit a Quote</strong> to the below of the RFQ title or “<strong>Submit Capability Statements/Quotes</strong>” below of the NOI title for which you wish to submit a quote/capability statement from the e-BidBoard "All Active RFQ’s/NOI’s" page.</li>
                                    <li>Enter a name for the quote or capability statement.</li>
                                    <li>Browse and select Business quote PDF, Capability statement PDF and Excel file(s) as required by the RFQ/NOI
                                    <ol type="a">
                                            <li>Forms and/or documents requiring signature(s) may be scanned, but must be merged into the single PDF file. Do NOT password-protect or encrypt the PDF file.</li>
                                            <li>Cost breakdown(s) as required by the RFQ/NOI, in its original Excel format, not the PDF format. The cost breakdown(s) in excel must be identical to the one(s) in the Business Quote/Capability Statement PDF.</li>
                                    </ol>
                                </li>
                                
                                    <li>Click <strong>Submit Quote for RFQ’s or Submit Capability Statements/Quotes for NOI’s</strong></li>
                                    <li>Once you upload and submit your documents, you can only revise your submitted documents by overwriting previously uploaded documents. You can find submissions to revise or view under <strong>My Submission History</strong>. You have up until the closing date and time of the RFQ/NOI to make as many revisions as you need.</li>
                                    <li>After the closing date and time, the system prevents further quote/capability statement submissions or revisions. You may still view your submissions after the RFQ/NOI closing date and time under <strong>My Submission History</strong>.</li>
                                </ol>
                            </Paper>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
};

export default HowToSubmit;
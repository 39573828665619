import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router";
import { ToastContainer } from "react-toastify";
import AppAccessDenied from "./components/Errors/AppAccessDenied";
import Error from "./components/Errors/Error";
import DeadlineError from "./components/Errors/DeadlineError";
import Error404 from "./components/Errors/Error404";
import AuthenticationFail from "./components/Errors/AuthenticationFail";
import About from "./components/About";
import HowToSubmit from "./components/HowToSubmit";
import ContactUs from "./components/ContactUs";
import Faq from "./components/Faq";
import AccountLocked from "./components/Errors/AccountLocked";
import { Layout } from "./components/Layout";
import { LayoutSecure } from "./components/secure/LayoutSecure";
import { Home } from "./components/Home";
import { FetchData } from "./components/FetchData";
import { SubmitProposal } from "./components/secure/SubmitProposal";
import { ReviseProposal } from "./components/secure/ReviseProposal";
import { Counter } from "./components/Counter";
import LoginForm from "./components/LoginForm";
import Logout from "./components/Logout";
import auth from "./services/authService";
//import RefreshToken from "./components/RefreshToken";
import NotFound from "./components/NotFound";
import ProtectedRoute from "./components/common/protectedRoute";
import { MySolicitations } from "./components/secure/MySolicitations";
import { ApiTester } from "./components/secure/ApiTester";
import { StaffManagement } from "./components/secure/StaffManagement";
import "react-toastify/dist/ReactToastify.css";
import IcRoleService from "./services/icRoleService";
import AppService from "./services/applicationService";
import "./custom.css";

import OtpLogin from "./components/OtpLogin"
import SubmissionHistory from "./components/secure/SubmissionHistory";
import { datadogRum } from '@datadog/browser-rum';

datadogRum.startSessionReplayRecording();

export default class App extends Component {
    static displayName = App.name;
    state = {};

    async componentDidMount() {
        const { data: datadogSettings } = await AppService.getDatadogSetting();
        this.setState({ datadogSettings });

        var domain = window.location.host.split(".")[0];
        var env = 'local';
        switch (domain) {
            case 'e-bidboard-dev':
                env = 'dev';
                break;
            case 'e-bidboard-stg':
                env = 'stage';
                break;
            case 'e-bidboard-qa':
                env = 'qa';
                break;
            case 'e-bidboard':
                env = 'production';
                break;
            default:
                env = 'local';
                break;
        }

        datadogRum.init({
                applicationId: this.state.datadogSettings.appId,
                clientToken: this.state.datadogSettings.clientToken,
                site: 'ddog-gov.com',
                service: 'BidBoard.External.WebUI',
                project: 'BidBoard',
                env: env,
                sampleRate: 100,
                premiumSampleRate: 100,
                trackInteractions: true,
                defaultPrivacyLevel: 'allow',

                beforeSend: (event, context) => {
                    if (event.type === 'resource' && event.resource.type === 'xhr') {
                        event.context = {
                            ...event.context,
                            responseHeaders: context.response?.headers ?? "",
                            xhrresponse: context.xhr.response
                        };
                    }
                }
            }
        );

        datadogRum.addRumGlobalContext(
            "domain",
            domain
        );

        const user = auth.getCurrentUser();
        if (user) {
            datadogRum.setUser({
                id: user.appId,
                name: user.firstName + " " + user.lastName,
                email: user.email,
                plan: 'premium'
            });
        }

        this.setState({ user: user });
    }

    routes = function (user) {
        return (
            <React.Fragment>
                <ToastContainer position="top-center" autoClose={5000} />

                <Layout /*user={user} icRoleSelector={this.icRoleSelector} defaultIcRole={this.state.selectedIcRole}*/ >
                    <Switch>
                        <Route path="/counter" component={Counter} />
                        <ProtectedRoute path="/fetch-data" component={FetchData} /> 
                        <ProtectedRoute path="/submitProposal/:id" component={SubmitProposal} />
                        <ProtectedRoute path="/reviseProposal/:id" component={ReviseProposal} />
                        <ProtectedRoute path="/MySolicitations" component={MySolicitations} />
                        <ProtectedRoute path="/submissionHistory" component={SubmissionHistory} />
                        {/*<Route path="/refreshToken" component={RefreshToken} /> */}
                        <Route path="/home" exact component={Home} />
                        <Route path="/" exact component={Home} />
                        <Route path="/About" component={About} />
                        <Route path="/HowToSubmit" component={HowToSubmit} />
                        <Route path="/contactUs" component={ContactUs} />
                        <Route path="/Faq" component={Faq} />
                        
                        {/*<Route path="/not-found" component={NotFound}></Route>*/}
                        <Route path="/otpLogin/:str" component={OtpLogin}></Route>
                        <Route path="/otpLogin" component={OtpLogin}></Route>
                        <Route path="/logout" component={Logout} />
                        <Route path="/accountLocked" component={AccountLocked}></Route>
                        <Route path="/appAccessDenied" component={AppAccessDenied}></Route>
                        <Route path="/error" component={Error}></Route>
                        <Route path="/deadlineerror" component={DeadlineError}></Route>
                        <Route path="/authenticationFail" component={AuthenticationFail}></Route>
                        <Route component={Error404}></Route>
                    </Switch>
                </Layout>
            </React.Fragment>
        )
    }

   render() {
       const { user } = this.state;
      //console.log(user);
      return (this.routes(user));
   }
}


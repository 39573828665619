import React, {Fragment }  from "react";
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const DateInput = ({ name, label, error,  value, onChange }) => {
    const inputLabelId = name + "-label";
    return (
              
        <div className="col-lg-3 col-md-6">
            <div className="MuiFormControl-root MuiTextField-root calendar-parent">
                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true" >
                    {label}</label>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Fragment>
                        <KeyboardDatePicker
                            id={name}
                            name={name}
                            value={value}
                            placeholder="MM/DD/YYYY"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            onChange={(date)=>onChange(name,date)}
                            minDate={new Date("01/01/2012")}
                            format="MM/dd/yyyy"
                            showTodayButton
                        />
                    </Fragment>
                </MuiPickersUtilsProvider>

            </div>
            {error && <small className="text-danger">{error}</small>}
        </div>
    );
};

export default DateInput;
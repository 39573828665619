import https from "./httpService";

export async function getSiteMaintenanceSetting() {
    return https.get("api/application/SiteMaintenanceSetting");
}

export async function getSupportBannerSetting() {
    return https.get("api/application/SupportBannerSetting");
}

export async function getDatadogSetting() {
    return https.get("api/application/DatadogSetting");
}

export default {
    getSiteMaintenanceSetting: getSiteMaintenanceSetting,
    getDatadogSetting: getDatadogSetting,
    getSupportBannerSetting: getSupportBannerSetting
};
import moment from 'moment-timezone';

export function getDate(dt) {
    var d = new Date(dt).toLocaleDateString();
    return d;
}
export function getDateEt(dt) {    // to show date when time must be in ET, in case of midnight
    var d = moment(dt).tz("America/New_York").format('l');
    return d;
}
export function getTimeHhMm(dt) {
    var t = new Date(dt).toLocaleTimeString();
    var hm = t.slice(0, t.lastIndexOf(':')) + ' ' + t.split(' ')[1];
    return hm;
}
export function getTimeHhMmSs(dt) {
    var hms = new Date(dt).toLocaleTimeString();
    return hms;
}
export function getTimeHhMmWithTimeZone(dt) {
    var t = new Date(dt).toLocaleTimeString();
    var hm = t.slice(0, t.lastIndexOf(':')) + ' ' + t.split(' ')[1] + ' [ET]';
    return hm;
}
export function getTimeHhMmWithTimeZoneEt(dt) {  // when time must be shown in ET
    var t = moment(dt).tz("America/New_York").format('LT');
    var hm = t + ' [ET]';
    return hm;
}
export function getTimeHhMmWithTimeZoneString(dt) {
    var hm = dt.slice(0, dt.lastIndexOf('E')) + ' [ET]';

    return hm;
}
export function getDateTimeWithTimeZone(dt) {
    var date = new Date(dt);
    var d = date.toLocaleDateString();
    var t = date.toLocaleTimeString();
    var t_part = t.slice(0, t.lastIndexOf(':')) + ' ' + t.split(' ')[1]
    var allStr = d + ' ' + t_part + ' [ET]';
    
    return allStr;
}
export default {
    getDate,
    getDateEt,
    getTimeHhMm,
    getTimeHhMmSs,
    getTimeHhMmWithTimeZone,
    getTimeHhMmWithTimeZoneEt,
    getTimeHhMmWithTimeZoneString,
    getDateTimeWithTimeZone
};
import React, { Component } from 'react';
import { toast } from "react-toastify";

import { Link } from "react-router-dom";
import _ from 'lodash';
//import auth from "../services/authService";
import Sidebar from './common/sidebar';
import Pagination from '@material-ui/lab/Pagination';
import RtopAttachmentsModal from "./secure/SolicitationTaskOrderDownloadsModal";
import solicitationService from "../services/solicitationService";
import HTTPStatusCodes from "../enums/HTTPStatusCodes";
import RecordStatus from "../enums/RecordStatus";
import SolicitationType from "../enums/SolicitationType";

export class SolicitationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            resultsPerPage: 20,
            frontEndPagination: true,
           rtopAttachmentsModal: {
                mode: 'view'
            },
            delegateStaffData: null,
            rtopAttachmentGroups: [],
            selectedSolicitationId: null,
            //todo: ecps code to remove {Neha Manya}
            selectedTaskAreaContractID: null,
            selectedSolicitationTitle: null,
            loading: false,
            delegateStaff: null,
            vendorOrgName: null,
            delegateStaffLoading: false,
            delegateStaffErrors: []
        }
    }

    

    handleOpenRtop = async (solicitationId, solicitationTitle) => {
        const add = Object.assign({}, this.state.rtopAttachmentsModal);
        add.open = 'rtopAttachments';
        this.setState({
            rtopAttachmentsModal: add,
            selectedSolicitationId: solicitationId,
            selectedSolicitationTitle: solicitationTitle
        });

        await solicitationService
            .getSolicitationAttachments(solicitationId)
            .then(result => {
                if (result.status === HTTPStatusCodes.SUCCESS)
                    this.setState({ rtopAttachmentGroups: result.data });
            })
            .catch(ex => {
                //console.log(ex);
                toast.error("Error while fetching attachments.");
            });
    }

    handleCloseRtop = () => {
        var add = Object.assign({}, this.state.rtopAttachmentsModal);
        add.open = '';
        add.openRolePanel = false;
        add.openUserList = false;
        add.ldapUserList = [];
        this.setState({ rtopAttachmentsModal: add, selectedSolicitationId: null });
    }

    solicitationTypeClass = (noticeTypeName) => {
        switch (noticeTypeName) {
            case 'Solicitation':
                return 'solicitation';
            case 'Combined Synopsis/Solicitation':
                return 'combined';
            case 'Sources Sought':
                return 'sources';
            case 'Presolicitation':
                return 'pre-notice';
            case 'Special Notice':
                return 'pre-notice';
            case 'Award':
                return 'award';
            default:
                return 'other';
        }
    };
    setPageNumber = (value) => {
        this.setState({ currentPage: value });
    }
    //getTimeHhMmWithTimeZoneString(dt) {
    //    var hm = dt.slice(0, dt.lastIndexOf('E')) + ' [ET]';

    //    return hm;
    //}

    renderAttachmentsButtons(solicitation) {
        return <div className="solicitation-toggleable-container">
            <button className="link-btn" onClick={() => this.handleOpenRtop(solicitation.solicitationID, solicitation.solicitationTitle)}>
                Attachments<img src="images/download-blue.svg" alt="download-attachments" />
            </button>
            <span className="project-banner-sol-number">{solicitation.solicitationNumber}</span>
        </div>;
    }

    render() {
        let totalPages = Math.ceil(this.props.solList.length / this.state.resultsPerPage);
        let startIndex = (this.state.currentPage - 1) * this.state.resultsPerPage;
        let endIndex = startIndex + this.state.resultsPerPage;
        let solsCurrentPage = this.state.frontEndPagination ? this.props.solList.slice(startIndex, endIndex) : this.props.solList;

        let updatePage = (event, newPageNumber) => {
            this.setPageNumber(newPageNumber);

            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        };
        let solsCountOnCurrentPage = this.props.solList.length <= 20 ? "Showing " + solsCurrentPage.length + " RFQ's/NOI's" : "Showing " + (startIndex + 1) + " to " + (startIndex + solsCurrentPage.length) + " of " + this.props.solList.length + " RFQ's/NOI's";
        return (

            <div className="container-fluid solicitation-landing external">
                {this.state.frontEndPagination && (<div className="pagination"><span className="rfq-count">{solsCountOnCurrentPage}</span> <Pagination count={totalPages} page={this.state.currentPage} onChange={updatePage} aria-label="top pagination naviation" /></div>)}
                

                {solsCurrentPage.map(sol => {
                    const solicitationId = sol.solicitationID;
                    return <div className="project-banner-preview combined" className={`project-banner-preview ${this.solicitationTypeClass(sol.noticeTypeName)}`} key={solicitationId}>
                        <div className="project-banner-header">
                            <div className="project-type">{sol.noticeTypeName}</div>
                            <div className="solicitation-banner-right">
                                {/* {this.props.isUserLoggedIn && false && <button className="project-invited"><span>Invited</span></button>} */}
                                {this.renderAttachmentsButtons(sol)}
                            </div>
                        </div>
                        <div className="project-banner-title">{sol.solicitationTitle}</div>
                        <div className="project-banner-footer">
                            <div className="banner-details">
                                {this.props.isUserLoggedIn ?
                                    (<div className="solicitation-action-btns external">
                                        <Link to={`/submitProposal/${solicitationId}`} >
                                            <button className="btn">
                                                <span>Submit {sol.noticeTypeName != "RFQ" ? "Capability Statements/Quotes" : "Quotes" }</span>
                                            </button>
                                        </Link>

                                        {/*//todo: ecps code to remove {Neha Manya}*/}
                                        {(sol.solicitationTypeId === SolicitationType.RTOP || sol.solicitationTypeId === SolicitationType.Other)
                                            && <button className="btn delegate-staff" onClick={() => this.handleOpenStaff(solicitationId, sol.taskAreaContractID, false)}>
                                            <span>Manage Delegate Staff</span>
                                            </button>}

                                        {sol.proposalSubmitted != 0 && <div className="proposal-submitted">
                                            <span className="submitted-count">{sol.proposalSubmitted}</span>
                                            <span> Submitted</span>
                                        </div>}
                                    </div>) :
                                    (<div className="button-container">
                                        <button className="MuiButtonBase-root MuiButton-root btn-white" tabIndex="0" type="button"
                                            onClick={() => { window.location = '/otp/renderOtp'; }}>
                                            <span className="MuiButton-label">
                                                Login to submit {sol.noticeTypeName != "RFQ" ? "capability statements/quotes" : "quotes" }</span>
                                            <span className="MuiTouchRipple-root"></span>
                                        </button>
                                    </div>)
                                }
                                <div className="MuiTableContainer-root">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col" className="sr-only">Action Buttons</th>
                                                <th scope="col">Purchasing Agent</th>
                                                <th scope="col">Agency</th>
                                                <th scope="col">Closing Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>

                                                </td>
                                                <td data-label="Contract Specialist">{sol.primaryPoc}</td>
                                                <td data-label="Agency">{sol.solicitationTargetIcAgency}/{sol.solicitationTargetIC}</td>
                                                <td data-label="Closing Date">{sol.responseDeadlineDateString} <small>{sol.responseDeadlineTimeString}</small></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                })
                }

                {this.state.frontEndPagination && (<div className="pagination"><Pagination count={totalPages} page={this.state.currentPage} onChange={updatePage} aria-label="bottom pagination navigation" /></div>)}

                <RtopAttachmentsModal formData={this} />
            </div>
        );
    }
}

export default SolicitationList;
import React, { Component } from "react";

export class SupportNoticeBanner extends Component {


    render() {
        const supportNoticeBannerMessage = this.props.supportNoticeBannerMessage;
        return (
            <div>
                <div className="support-notice">
                    <div className="support-notice-inner">{supportNoticeBannerMessage}</div>
                </div>
            </div>
        );
    }
}
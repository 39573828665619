import { format } from 'date-fns-tz'
import { enUS } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';

export default class DateFnsTzUtils extends DateFnsUtils {
    constructor(props) {
        super();
        this.timeZone = "America/New_York";
        this.locale = enUS;
    }
    format(date, formatString) {
        var i = format(date, formatString, {
            timeZone: this.timeZone,
            locale: this.locale
        });

        return i;
    }
}
import React, { Component } from 'react';
import HTTPStatusCodes from "../../enums/HTTPStatusCodes";
import Joi from 'joi-browser';
import axios from 'axios';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

// import '../siteStyles.css';
import ProgressModal from './progressModal';

import * as constants from "../common/constants";
import FileUpload from "../common/fileUpload";
import fileDownload from 'js-file-download';
import Form from "../common/form";
import auth from "../../services/authService";
import fileService from "../../services/fileService";
import { SolicitationHeader } from "./SolicitationHeader";
import submitProposalService from "../../services/submitProposalService";
import ProposalSubmissionSuccessModal from "./ProposalSubmissionSuccessModal";
import { createBrowserHistory } from 'history'
import DateTimeConvert from '../common/DateTimeConversionMethods';
import Footer from "../Footer";
export class ReviseProposal extends Form {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                proposalName: ''
            },
            businessExcelFiles: [
                
            ],
            businessDocFiles: [
                
            ],
            businessExcelFileIndex: 0,
            businessDocFileIndex: 0,
            solicitation: {},
            errors: {},
            pageError: false,
            previousBusinessDocFileLoaded: true,
            previousBusinessExcelFileLoaded: true,
            originalProposalName:"",
            uploadedFiles: [],
            uploadedFileChanges: [],
            changedDocumentIds:[],
            totalUploadedFileChangesSize:0,
            headerInfo: {}, 
            vendorContactInfo: {},
            isTechPdfLoaded: false,
            isBusinessPdfLoaded: false,
            techPdfRequired: "",
            businessPdfRequired: "",
            techPdfValidationError: "",
            businessPdfValidationError: "",
            humanPdfValidationError: "",
            proposal: {},
            confirmationModal: {},
            loading: false,
            loadedAmount: 0,
            openProgressModal: false,
            setopenProgressModal: false,
            showDownloadButton: false
        };

        this.schema = {
            proposalName: Joi.string().trim().required().error(() => { return { message: this.state.solicitation.noticeTypeName != "RFQ" ? "Capability Statement/Quote Name required." : "Quote Name required." }; }),
        };


        this.onFileDelete = this.onFileDelete.bind(this);
        this.onFileReplace = this.onFileReplace.bind(this);
        this.addBusinessDoc = this.addBusinessDoc.bind(this);
        this.addBusinessExcelDoc = this.addBusinessExcelDoc.bind(this);
        this.onBusinessExcelFileUpload = this.onBusinessExcelFileUpload.bind(this);
        this.onBusinessExcelFileDelete = this.onBusinessExcelFileDelete.bind(this);
        this.onBusinessDocFileUpload = this.onBusinessDocFileUpload.bind(this);
        this.onBusinessDocFileDelete = this.onBusinessDocFileDelete.bind(this);
       }
    async addBusinessDoc() {
        
        if (!this.state.previousBusinessDocFileLoaded) {
            return;
        }
        //console.log("Add additional business  document");

        let businessDocFileIndex = this.state.businessDocFileIndex;
        businessDocFileIndex++;

        let item = { id: "businessPDF" + businessDocFileIndex, label: "Business PDF", isLoaded: false, errorMessage: "" };

        let businessDocFiles = [...this.state.businessDocFiles, item];
        this.setState({ businessDocFileIndex, businessDocFiles, previousBusinessDocFileLoaded: false });

        //console.log("Business Doc Files", businessDocFiles);

    }
    async addBusinessExcelDoc() {
        if (!this.state.previousBusinessExcelFileLoaded) {
            return;
        }
       
        let businessExcelFileIndex = this.state.businessExcelFileIndex;
        businessExcelFileIndex++;
        
        let item = { id: "businessExcel" + businessExcelFileIndex, label: "Business Excel", errorMessage: "", defaultFileName: "", fileDbId:0 };
        
        let businessExcelFiles = [...this.state.businessExcelFiles, item];
        this.setState({ businessExcelFileIndex, businessExcelFiles, previousBusinessExcelFileLoaded: false });

    }
    async onFileDelete(fileDbId,docId, isReplace) {
        //console.log("Revise Proposal File delete file id", fileDbId);
        //console.log("Revise Proposal File delete file is replace", isReplace);
        const objToRemove = this.state.uploadedFileChanges.find(obj => obj.fileDbId === fileDbId);
        //console.log("Revise proposal object to remove", objToRemove);
        if (objToRemove !== undefined) {
            try {
                this.updateStateForFileChanges(objToRemove); 
                return true;
            } catch (e) {
                //console.log("Revise Proposal File Delete Error", e);
                return false;
            }
        }
        return false;

    }

    async onFileDownload(fileDbId, fileName) {
        const url = fileService.getDownloadUrl(fileDbId);
        try {            
            const response = await fileService.handleDownload(url, fileName);
            await fileDownload(response.data, fileName);
        }
        catch (ex) {
            toast.error("Download file error");
        }
        
    }
    updateStateForFileChanges(objToRemove) {
        //console.log("updateStateForFileChanges", objToRemove);
        const uploadedFileChanges = this.state.uploadedFileChanges.filter(obj => obj.fileGuid !== objToRemove.fileGuid);
       
        let totalUploadedFileChangesSize = this.state.totalUploadedFileChangesSize - objToRemove.file.size;
        totalUploadedFileChangesSize = totalUploadedFileChangesSize || 0;
        this.setState({ uploadedFileChanges, totalUploadedFileChangesSize });
    }

    
    async onBusinessExcelFileDelete(fileDbId, id, isReplace) {

        try {
            //console.log("Business Excel File Delete Id:", id);
            //console.log("Business Excel File Delete fileDbId:", fileDbId);
            //console.log("Business Excel File Delete isReplace:", isReplace);

            const isFileDbIdGuid = submitProposalService.isGUID(fileDbId);
            if (isFileDbIdGuid) {
                //console.log("its a guid");
                if (!isReplace){
                    this.removeBusinessExcelDoc(id);                
                }
                
                const objToRemove = this.state.uploadedFileChanges.find(obj => obj.fileGuid === fileDbId);
                const uploadedFileChanges = this.state.uploadedFileChanges.filter(obj => obj.fileGuid !== fileDbId);
                
                let totalUploadedFileChangesSize = this.state.totalUploadedFileChangesSize - objToRemove.file.size;
                totalUploadedFileChangesSize = totalUploadedFileChangesSize || 0;
                this.setState({ uploadedFileChanges, totalUploadedFileChangesSize});
                return true;
            }

            if (!isReplace) {
                const objToRemove = this.state.businessExcelFiles.find(obj => obj.id === id);
                if (objToRemove.fileDbId === 0) {
                    //console.log("Revise proposal Business excel -Remove the Component as well");

                    this.removeBusinessExcelDoc(id);

                } else {
                    this.setState({ previousBusinessExcelFileLoaded: false });
                }
            }
            
            const objToRemove = this.state.uploadedFileChanges.find(obj => obj.fileDbId === fileDbId);
           
            //console.log("Revise proposal business excel file delete object to remove", objToRemove);
            if (objToRemove !== undefined) {
                try {
                    this.updateStateForFileChanges(objToRemove); 
                    return true;
                } catch (e) {
                    //console.log("Revise Proposal File Delete Error", e);
                    return false;
                }
            }
            return true;

        } catch (e) {
            //console.log("Error in Business excel file delete", e);
            return false;
        }


    }
    removeBusinessExcelDoc(id) {
        //console.log("Revise proposal- Removing Business excel doc");
        let businessExcelFiles = this.state.businessExcelFiles;
        const businessExcelFilesFiltered = businessExcelFiles.filter(obj => obj.id !== id);
        setTimeout(() => {
                this.setState({
                    businessExcelFiles: businessExcelFilesFiltered,
                    previousBusinessExcelFileLoaded: true
                });
            },
            1);

    }

    async onBusinessDocFileDelete(fileDbId, id, isReplace) {

        try {
            //console.log("Business Doc File Delete Id:", id);
            //console.log("Business Doc File Delete fileDbId:", fileDbId);
            const isFileDbIdGuid = submitProposalService.isGUID(fileDbId);
            if (isFileDbIdGuid) {
                //console.log("its a guid");
                if (!isReplace) {
                    this.removeBusinessDocFile(id);
                }
                
                const objToRemove = this.state.uploadedFileChanges.find(obj => obj.fileGuid === fileDbId);
                const uploadedFileChanges = this.state.uploadedFileChanges.filter(obj => obj.fileGuid !== fileDbId);
               
                let totalUploadedFileChangesSize = this.state.totalUploadedFileChangesSize - objToRemove.file.size;
                totalUploadedFileChangesSize = totalUploadedFileChangesSize || 0;
                this.setState({ uploadedFileChanges, totalUploadedFileChangesSize });
                return true;
            }
            if (!isReplace) {
                const objToRemove = this.state.businessDocFiles.find(obj => obj.id === id);
                if (objToRemove.fileDbId === 0) {
                    //console.log("Remove the Component as well");
                    this.removeBusinessDocFile(id);
                    
                } else {
                    let businessDocFiles = this.state.businessDocFiles;
                    const objIndex = businessDocFiles.findIndex(obj => obj.id === id);
                    businessDocFiles[objIndex].isLoaded = false;
                    businessDocFiles[objIndex].errorMessage = "";
                    this.setState({ businessDocFiles });
                    this.setState({ previousBusinessDocFileLoaded: false });
                }

            }
           
            
            const businessDocToRemove = this.state.uploadedFileChanges.find(obj => obj.fileDbId === fileDbId);
            //console.log("Revise proposal business doc file delete object to remove", businessDocToRemove);
            if (businessDocToRemove !== undefined) {
                try {
                    this.updateStateForFileChanges(businessDocToRemove);
                    return true;
                } catch (e) {
                    //console.log("Revise Proposal File Delete Error- Business doc", e);
                    return false;
                }
            }
            return true;

        } catch (e) {
            //console.log("Error in Business doc file delete", e);
            return false;
        }

    }

    removeBusinessDocFile(id) {
        let businessDocFiles = this.state.businessDocFiles;
        const businessDocFilesFiltered = businessDocFiles.filter(obj => obj.id !== id);
        

        setTimeout(() => {
            this.setState({
                businessDocFiles: businessDocFilesFiltered,
                previousBusinessDocFileLoaded: true
            });
        },1);
    }

    async onBusinessExcelFileUpload(file, id, fileDbId) {
        const totalSizeAfterUpload = this.state.totalUploadedFileChangesSize + file.size;
        if (file.size > constants.MaxContentSizeAllowed || totalSizeAfterUpload > constants.MaxContentSizeAllowed)
            return constants.MaxContentSizeLimitExceeded;

        try {
            //console.log("Business excel File upload id", id);
            //console.log("file", file);
            let businessExcelFiles = this.state.businessExcelFiles;
            const objIndex = businessExcelFiles.findIndex(obj => obj.id == id);

            var validExts = new Array(".xlsx", ".xls");
            var fileExt = file.name;
            fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
            if (validExts.indexOf(fileExt) < 0) {
                businessExcelFiles[objIndex].errorMessage = "Only Excel files are allowed";
                this.setState({ businessExcelFiles });
                return "NotAllowed";
            }
           
            const fileGuid = await submitProposalService.guid();
            const item = { fileGuid, fileName: file.name, fileType: constants.BusinessExcel, fileDbId, file}
            
            let uploadedFileChanges = [...this.state.uploadedFileChanges, item];
           
            businessExcelFiles[objIndex].errorMessage = "";
            let totalUploadedFileChangesSize = this.state.totalUploadedFileChangesSize + file.size;
            
            await this.setState({ uploadedFileChanges, businessExcelFiles, previousBusinessExcelFileLoaded: true, totalUploadedFileChangesSize });

            //console.log("Uploaded Files", this.state.uploadedFiles);
            return fileGuid;
        } catch (e) {
            //console.log(e);
            return "Error";
        }
    }

    async onBusinessDocFileUpload(file, id, fileDbId) {
        const totalSizeAfterUpload = this.state.totalUploadedFileChangesSize + file.size;
        if (file.size > constants.MaxContentSizeAllowed || totalSizeAfterUpload > constants.MaxContentSizeAllowed)
            return constants.MaxContentSizeLimitExceeded;

        try {
            //console.log("Business doc File upload id", id);
            //console.log("file", file);

            let businessDocFiles = this.state.businessDocFiles;
            const objIndex = businessDocFiles.findIndex(obj => obj.id == id);

            var validExts = new Array(".xlsx", ".xls", ".pdf", ".doc", ".docx");
            var fileExt = file.name;
            fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
            if (validExts.indexOf(fileExt) < 0) {
  
                businessDocFiles[objIndex].errorMessage = "Only Excel, Word or PDF files are allowed";
                businessDocFiles[objIndex].isLoaded = false;
                this.setState({ businessDocFiles });
                return "NotAllowed";
            }


            const fileGuid = await submitProposalService.guid();
            const item = { fileGuid, fileName: file.name, fileType: constants.BusinessExcel, fileDbId, file }
            let uploadedFileChanges = [...this.state.uploadedFileChanges, item];
            let totalUploadedFileChangesSize = this.state.totalUploadedFileChangesSize + file.size;
            await this.setState({ uploadedFileChanges, totalUploadedFileChangesSize });

            businessDocFiles[objIndex].isLoaded = true;
            businessDocFiles[objIndex].errorMessage = "";
            this.setState({ businessDocFiles });

            this.setState({ previousBusinessDocFileLoaded: true });
            return fileGuid;
        } catch (e) {
            //console.log(e);
            return "Error";
        }
    }
    async onFileReplace(file, id, fileDbId) {
        const techPdf = "techPDF";
        const humanPdf = "humanPDF";
        const businessPdf = "businessPDF";
        //console.log("Revise proposal file replace file", file);
        //console.log("Revise proposal file replace file id", id);
        //console.log("Revise proposal file replace db file id", fileDbId);

        const totalSizeAfterUpload = this.state.totalUploadedFileChangesSize + file.size;
        if (file.size > constants.MaxContentSizeAllowed || totalSizeAfterUpload > constants.MaxContentSizeAllowed)
            return constants.MaxContentSizeLimitExceeded;

        try {

            var validExts = new Array(".pdf");
            var fileExt = file.name;
            fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
            if (validExts.indexOf(fileExt) < 0) {

                if (id === techPdf) {
                    this.setState({ techPdfValidationError: " Only PDF file allowed" });
                }

                if (id === humanPdf) {
                    this.setState({ humanPdfValidationError: " Only PDF file allowed" });
                }

                if (id === businessPdf) {
                    this.setState({ businessPdfValidationError: " Only PDF file allowed" });
                }
                return "NotAllowed";
            }

            let fileType = -1;
            if (id === techPdf) {
                fileType = constants.TechPdf;
                this.setState({ isTechPdfLoaded: true, techPdfRequired: "", techPdfValidationError: "" });
            }

            if (id === humanPdf) {
                fileType = constants.HumanPdf;
                this.setState({ humanPdfValidationError: "" });
            }

            if (id === businessPdf) {
                fileType = constants.BusinessPdf;
                this.setState({ isBusinessPdfLoaded: true, businessPdfRequired: "", businessPdfValidationError: "" });
            }

            const fileGuid = await submitProposalService.guid();
            const item = { fileGuid, fileName: file.name, fileType, fileDbId, file }
            let uploadedFileChanges = [...this.state.uploadedFileChanges, item];
            let totalUploadedFileChangesSize = this.state.totalUploadedFileChangesSize + file.size;
            await this.setState({ uploadedFileChanges, totalUploadedFileChangesSize});

            return fileGuid;
        } catch (e) {

            //console.log(e);
            return "Error";

        }
    }

    //Change to avoid change state after un-mount caused by self.refreshPage()
    async componentWillMount() {
        try {
            await this.loadPage();
        } catch (ex) {
            toast.error("Error loading capability statement/quote, please try again");
            setTimeout(() => window.location = "/", 3000);
        }
        
    }
 
    loadPage = async () => {
        const proposalId = this.props.match.params.id;
        try {
            this.getPageHeaderWithVendorContactInfo(proposalId);
            const { data: solicitation } = await submitProposalService.getSolProposalSubmissionDataByProposalID(proposalId);
            const { data: proposal } = await submitProposalService.GetReviseProposalData(proposalId);

            //console.log("received proposal", proposal);

            let uploadedFiles = proposal.files;

            //let totalUploadedFileSize = 0;
            //uploadedFiles.forEach((item) => {
            //    totalUploadedFileSize += item.size || 0;
            //});
            const fetchedBusinessExcelFiles = proposal.files.filter(f => f.type === constants.BusinessExcel);
            let businessExcelFiles = [];
            if (fetchedBusinessExcelFiles.length > 0)
                businessExcelFiles = fetchedBusinessExcelFiles.map((f, index) => ({
                    id: 'businessExcel' + index,
                    label: 'Business Excel',
                    errorMessage: '',
                    defaultFileName: f.fileName,
                    fileDbId: f.id
                }));
            const businessExcelFileIndex = fetchedBusinessExcelFiles.length - 1;
            let businessDocFiles = proposal.files.filter(f => f.type === constants.NonRDBusinessDocs);
            if (proposal.files.length > 0)
                businessDocFiles = proposal.files.map((f, index) => {
                    return {
                        id: 'businessPDF' + index,
                        label: 'Business Document',
                        isLoaded: false,
                        errorMessage: '',
                        defaultFileName: f.fileName,
                        fileDbId: f.id
                    }
                });
            let businessDocFileIndex = businessDocFiles.length - 1;
            

            setTimeout(() => {
                    this.setState({
                        solicitation,
                        proposal,
                        uploadedFiles,
                        //totalUploadedFileSize,
                        data: { proposalName: proposal.proposalName },
                        originalProposalName: proposal.proposalName,
                        businessExcelFiles: businessExcelFiles,
                        businessExcelFileIndex,
                        businessDocFiles: businessDocFiles,
                        businessDocFileIndex,
                        //previousBusinessExcelFileLoaded: fetchedBusinessExcelFiles.length > 0,
                        //previousBusinessDocFileLoaded: proposal.files.length > 0,
                        submitHistory: proposal.historyLogs
                    });
                },
                5);
            const history = createBrowserHistory();
            if (this.props.location.state && this.props.location.state.showProposalSubmissionSuccessModal) {
                this.handleOpen('open');
                this.setState({ changedDocumentIds: this.props.location.state.changedDocumentIds });
                let state = { ...history.location.state };
                delete state.showProposalSubmissionSuccessModal;
                delete state.changedDocumentIds;
                history.replace({ ...history.location, state });
            }

            //if (this.props.location.state && this.props.location.state.changedDocumentIds) {
            //    console.log("changed dcoument ids", this.props.location.state.changedDocumentIds);
            //    setTimeout(() => {
            //            this.setState({ changedDocumentIds: this.props.location.state.changedDocumentIds });
            //        },
            //        5);

            //}
        } catch (ex) {
            //console.log(ex);
            this.setState({ pageError: true });
            toast.error(ex.response.data);
            setTimeout(() => window.location = "/", 3000);
        }
        finally {
            this.setState({ loading: false });
        }
    }

    handleOpen = () => {
        const add = Object.assign({}, this.state.confirmationModal);
        add.open = 'open';
        this.setState({ confirmationModal: add, showDownloadButton: true });
    }
    handleClose = () => {
        var add = Object.assign({}, this.state.confirmationModal);
        add.open = '';
        this.setState({ confirmationModal: add });
    }
    async getPageHeaderWithVendorContactInfo(proposalId) {
        try {
            let {data} = await submitProposalService.vendorContactInfo(proposalId);
            this.setState({ headerInfo: data.headerInfo, vendorContactInfo: data.vendorContactInfo});

        } catch (ex) {
            //console.log(ex);
        }
    }
    refreshPage(changedDocumentIds) {
        const proposalId = this.state.proposal.proposalId;
      this.props.history.push({
          pathname: '/reviseProposal', state: { showProposalSubmissionSuccessModal: false, changedDocumentIds:[] }
      });
      setTimeout(() => {
              this.props.history.push({
                  pathname: `/reviseProposal/${proposalId}`, state: { showProposalSubmissionSuccessModal: true, changedDocumentIds }
              });
          },
          5);
    }
    doSubmit = async () => {
        const pageGuid = this.state.solicitation.pageGuid;
        const solicitationId = this.state.solicitation.solicitationID;
        const proposalId = this.state.proposal.proposalId;
        const proposalName = this.state.data.proposalName;
        const uploadedFileChanges = this.state.uploadedFileChanges;
        const proposalData = { proposalId, proposalName, pageGuid, uploadedFileChanges }
        const self = this;

        //do not submit if nothing changed
        if (this.state.originalProposalName === proposalName && uploadedFileChanges.length === 0)
            return false;

        try {
            const user = auth.getCurrentUser();
            let newSelf = this;
            const res = await submitProposalService.postSubmitGateway(user, solicitationId, 2);
            if (res.status !== HTTPStatusCodes.SUCCESS) {
                toast.error("Submission gateway error");
                return false;
            }

            const { data, status } = await submitProposalService.postSubmitProposal('editProposal',
                solicitationId,
                proposalData,
                newSelf,
                "api/solicitations/",
                "/updateProposal");
            if (status === HTTPStatusCodes.SUCCESS) {
                console.log("Update " + this.state.solicitation.noticeTypeName != "RFQ" ? "Capability Statement/Quote": "Quote" + " success", data);

                this.handleOpen('open');
                let changedDocumentIds = data.changedDocumentIds;
                setTimeout(() => {
                        this.setState({ originalProposalName: this.state.data.proposalName, uploadedFileChanges: [] });
                        this.setState({
                            setopenProgressModal: false,
                            openProgressModal: false,
                            loadedAmount: 0
                            //loading: false
                        });
                    },
                    5);
                
                //await this.loadPage();
                //Need to handle unmounted issue
                this.refreshPage(changedDocumentIds);
            } else {
                
                toast.warning("Upload not quite right");
            }
        } catch (ex) {
            if (ex.toString().indexOf("CANCEL_UPLOAD") >= 0) {
                toast.warning("Upload canceled");
            } 

            if (ex.response.status === 403) {
                toast.error(ex.response.data);
                setTimeout(() => window.location = "/deadlineerror", 3000);
            } else {
                toast.error("Submission fail");
                setTimeout(() => window.location = "/", 3000);
            }
            this.setState({ uploadedFileChanges: [] });

            this.setState({
                setopenProgressModal: false,
                openProgressModal: false,
                loadedAmount: 0
            });
        } finally {
            this.setState({ loading: false });
        }
    };

    isPdf = (fileName) =>{
        const extension = fileName.split('.').pop().toLowerCase();
        return extension === 'pdf';
    }

    historyText = (ph) => {
        var test = ph.proposalLogComment.match(/("|')([^"'\\]+)("|')(\s+)replaced(\s+)("|')([^"'\\]+)("|')/g);
        if (test) {
            var filesFromComment = ph.proposalLogComment.match(/(["'])(?:(?=(\\?))\2.)*?\1/g)
            if (filesFromComment && filesFromComment.length > 1) {
                var newFile = filesFromComment[0].replace(/[\"\']/g, "");
                var oldFile = filesFromComment[1].replace(/[\"\']/g, "");
                return (<div><strong>{ph.newValue}</strong> replaced <strong>{oldFile}</strong></div>);               
            }
        }

        test = ph.proposalLogComment.match(/(["'])(?:(?=(\\?))\2.)*?\1/g);
        if (test) {
            var newFile = ph.proposalLogComment.replace(/[\"\']/g, "");
            return (<div><strong>{newFile}</strong></div>);
        }

        if (ph.newValue) {
            return (<div><strong>{ph.newValue}</strong>: {ph.proposalLogComment.replace(/[\"\']/g, "")}</div>);
        } else {
            return (<div><strong>{ph.proposalLogComment.replace(/[\"\']/g, "")}</strong></div>);
        }        
    }

    isRedirectedFromSubmitProposal() {
        const location = this.props.location;
        if (location === undefined || location === null)
            return false;

        const from = location.from;
        if (from === undefined || from === null)
            return false;

        return from === "Submit " + this.state.solicitation.noticeTypeName != "RFQ" ? "Capability Statement/Quote" : "Quote" + " Page";
    }

    checkSubmitDisabled() {
        const errors = this.validate();
        const state = this.state;
        const uploadedFileChanges = state.uploadedFileChanges;

        return ((errors != null)
                    || (state.originalProposalName === state.data.proposalName && uploadedFileChanges.length === 0));
    }

    onCancelUploading = () => {
        //console.log('onCancelUploading');
        this.setState({
            setopenProgressModal: false, 
            openProgressModal: false,
            loadedAmount: 0,
            //cancelSubmission: true
        });

        submitProposalService.postSubmitProposal('editProposal', null, null, null, 'api/solicitations/', '/updateProposal', true);

        //this.setState({
        //    setopenProgressModal: false,
        //    openProgressModal: false,
        //    loadedAmount: 0,
        //    //cancelSubmission: true
        //});
    }

    render() {
        if (this.state.pageError)
            return null;

        const loader = (<div className="loader" style={{ position: "fixed", top: "50%", left: "45%" }}>Loading...</div>);
        const isSubmitDisabled = this.checkSubmitDisabled();

        return (
            <div>
                <div id="content" className="content">
                    <SolicitationHeader headerInfo={this.state.headerInfo}/>
                    <div className="main-content centered-content">
                        <div className="container-fluid">
                            {this.state.loading && loader}
                            {!this.state.loading && <div className="row tile-height-row">
                                <div className="col-xl-8">
                                    <div className="section-heading">Edit {this.state.solicitation.noticeTypeName != "RFQ" ? "Capability Statement/Quote" : "Quote"}</div>
                                    <div className="tile-section form-section submit-proposal">
                                        <form onSubmit={e => {e.preventDefault(); }}>

                                            {this.renderInput("proposalName", this.state.solicitation.noticeTypeName != "RFQ" ? "Capability Statement/Quote Name" : "Quote Name", "col-lg-9", "text", true)}
                                        </form>
                                        <div className="additional-materials">
                                            <div className="material-head">
                                                <div className="material-info">
                                                    <div className="material-title">{this.state.solicitation.noticeTypeName != "RFQ" ? "Capability Statement/Quote" : "Quote"} Documents</div>
                                                    <small>Max File Upload Size: 387.19 MB</small>
                                                </div>
                                                <div className="material-req">
                                                    {!this.state.solicitation.solicitationIsRD && <p>Only excel, word or pdf formats can be submitted. Multiple individual files may be submitted.</p>}

                                                </div>
                                            </div>
                                            <div className="proposal-upload-alert">
                                                <span><strong>Note:</strong> Updated files will not be uploaded until the {this.state.solicitation.noticeTypeName != "RFQ" ? "capability statement/quote" : "quote"} is submitted.</span>
                                            </div>
                                            <ul className="chevron-list">
                                                
                                                {!this.state.solicitation.solicitationIsRD && this.state.businessDocFiles.map((item) => {
                                                    return (<li key={item.id} className="business-doc">
                                                        <FileUpload
                                                            dynamicClass="business-doc-upload"
                                                            label={item.label}
                                                            id={item.id}
                                                            required={true}
                                                            accept=".xlsx,.xls, .doc, .docx,.pdf"
                                                            errorMessage={item.errorMessage}
                                                            fileUpload={this.onBusinessDocFileUpload}
                                                            fileDownload={this.onFileDownload}
                                                            fileDelete={this.onBusinessDocFileDelete}
                                                            defaultFile={item.defaultFileName}
                                                            fileDbId={item.fileDbId}
                                                            isDownloadAllowed={(this.state.showDownloadButton && this.state.changedDocumentIds && this.state.changedDocumentIds.includes(item.fileDbId) )||this.isRedirectedFromSubmitProposal()}
                                                        />
                                                    </li>);
                                                })}
                                                


                                            </ul>
                                           
                                            {!this.state.solicitation.solicitationIsRD && (<div className="add-doc">
                                                <button className="MuiButtonBase-root MuiButton-root MuiButton-contained success-long-btn" tabIndex="0" type="button"
                                                    onClick={this.addBusinessDoc} disabled={!this.state.previousBusinessDocFileLoaded}>
                                                    <span className="MuiButton-label">
                                  <img src="images/plus-white.svg" alt="add-additional-document" />Add Additional Business Document
                                            </span>
                                                    <span className="MuiTouchRipple-root"></span>
                                                </button>
                                            </div>
                                            )}
                                        </div>

                                        <div className="tile-footer submit-container"
                                            title={isSubmitDisabled ? this.state.data.proposalName === "" ? "Capability Statement/Quote Name Required" : "Button will be enabled if there are changes to the capability statement/quote" : ""}>
                                            {isSubmitDisabled && <div className="inst document-requirement">Submit button will be enabled once all of the required information is provided.</div>}
                                            <button 
                                              className={`MuiButtonBase-root MuiButton-root ${isSubmitDisabled ? 'disabled-btn' : ''} MuiButton-contained submit-btn btn-primary`} 
                                              tabIndex="0"
                                              type="submit"
                                              disabled={isSubmitDisabled}
                                              onClick={(uploading) => {this.handleSubmit(uploading); this.setState({setopenProgressModal: true, openProgressModal: true}); }}
                                            >
                                              <span className="MuiButton-label">
                                                    Submit Updated {this.state.solicitation.noticeTypeName != "RFQ" ? "Capability Statement/Quote" : "Quote"}
                                              </span>
                                              <span className="MuiTouchRipple-root"></span>
                                          </button>
                                      </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div>
                                        <br/>
                                        <br />
                                        <Link
                                            to={`/submitProposal/${this.state.solicitation.solicitationID}`}
                                            className="MuiButtonBase-root MuiButton-root disabled-btn MuiButton-contained new-alt-prop"
                                            tabIndex="0"
                                            type="button">
                                            <span className="MuiButton-label">
                                                Submit New/Alternate {this.state.solicitation.noticeTypeName != "RFQ" ? "Capability Statement/Quote" : "Quote"}
                                            </span>
                                            <span className="MuiTouchRipple-root"></span>
                                        </Link>
                                        <br/>
                                        <br/>
                                    </div>
                                    <div className="section-heading">Your Contact Information</div>
                                    <div className="tile-section activity-history tile-height tile-footer-scroll">
                                        <div >
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td><strong>Vendor</strong></td>
                                                    <td>{this.state.vendorContactInfo.vendor}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Name</strong></td>
                                                    <td>{this.state.vendorContactInfo.name}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Email</strong></td>
                                                    <td>{this.state.vendorContactInfo.email}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Phone</strong></td>
                                                    <td>{this.state.vendorContactInfo.phone}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="section-heading">Submission History</div>
                                    <div className="tile-section activity-history tile-height tile-footer-scroll">
                                        <div className="tile-title comment-title">File/Date</div>
                                        <ul className="circle-line-list scroll-list" style={{maxHeight: "250px"}}>
                                            {this.state.submitHistory && this.state.submitHistory.map((ph, index) => {
                                                
                                                return (

                                                    <li key={index}>
                                                        <div className="circle-icon"></div>
                                                        <div className="comment-user-time">{this.historyText(ph)}</div>
                                                        <div className="comment-user-time">
                                                            <div className="comment-time">{ph.proposalLogDateStr} {ph.proposalLogTimeStr}</div>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                    {ProposalSubmissionSuccessModal(this)}
                    <ProgressModal
                        openProgressModal={this.state.openProgressModal}
                        setopenProgressModal={this.state.setopenProgressModal}
                        open={this.state.openProgressModal}
                        modalType='upload-progress'
                        title='Submission in progress'
                        loadedAmount={this.state.loadedAmount}
                        selectedValue={'upload progress bar'}
                        onClose={() => this.onCancelUploading()}
                    >
                    </ProgressModal>
                </div>
                <Footer/>
            </div>
);
}
}

export default ReviseProposal;

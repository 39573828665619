import https from "./httpService";
import auth from "../services/authService";

export async function getSolicitations(search, sortBy) {

    const user = await auth.getCurrentUser();

    const startDt = new Date(search.closingDateStart);
    const startDtStr = startDt.getFullYear() + '-' + (startDt.getMonth() + 1) + '-' + startDt.getDate() + 'T00:00:00.000Z';

    const endDt = new Date(search.closingDateEnd);
    const endDtStr = endDt.getFullYear() + '-' + (endDt.getMonth() + 1) + '-' + endDt.getDate() + 'T00:00:00.000Z';

    var searchQuery = "";
 
    if (search) {
        searchQuery = "closingDateStart=" + startDtStr + "&closingDateEnd=" + endDtStr + "&searchKeyword=" + search.keyword;
    }

    //var filterQuery = "&filter=" + filter;
    var sortByQuery = "&sortBy=" + sortBy;

    if (!user) {
        return https.get(
            "api/solicitations/getSolicitations?"/* + icIdQuery*/ + searchQuery/* + filterQuery*/ + sortByQuery
        );
    } else {
        return https.get(
            "api/solicitations/getSolicitationsSecure?"/* + icIdQuery*/ + searchQuery/* + filterQuery*/ + sortByQuery
        );
    }    
}

export async function getSolicitation(solId) {

    if (!solId) {
        return null;
    }

    return https.get(
        "api/solicitations/Solicitation/" + solId
    );
}

export async function getDropDownListForSolicitation(icId) {
    //console.log(" Getting drop down data for icid:", icId);
    if (!icId) {
        return null;
    }

    return https.get(
        "api/solicitations/GetSolicitationCreationDropdownList?icId=" + icId
    );
}

export async function saveSolicitation(icid,solicitation) {
    const body = { ...solicitation }
    body.ActiveIc = icid;

    //console.log("Solicitation data to Save: ", body);
    
    return https.post("api/solicitations/create", body);
}

export async function getProposalById(proposalId) {
    if (!proposalId)
        return null;

   return https.get(
        "api/proposals/GetProposalById/" + proposalId
    );
}

export async function editSolicitation(icid, solicitation) {
    const body = { ...solicitation }
    body.ActiveIc = icid;

    //console.log("Solicitation data to Save: ", body);

    return https.post("api/solicitations/edit", body);
}

export async function getSolicitationAttachments(solicitationId) {
    if (!solicitationId)
        return null;

    return https.get(`api/solicitations/GetSolicitationAttachments/${solicitationId}`);
}



export default {
    getSolicitations: getSolicitations,
    getDropDownListForSolicitation: getDropDownListForSolicitation,
    saveSolicitation: saveSolicitation,
    getSolicitation: getSolicitation,
    getProposalById: getProposalById,
    editSolicitation: editSolicitation,
    getSolicitationAttachments: getSolicitationAttachments

};
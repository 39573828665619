import axios from "axios";
//import { toast } from "react-toastify";
//import auth from "./authService";
import { ecpsExtTokenCreation_Key, ecpsExtRefreshInProgress_Key } from "../config.json";
import logger from "./logService";

import {
    ecpsExternalIdToken_Key,
    ecpsExternalAccessToken_Key,
    ecpsExternalRefreshToken_Key,
    ecpsExternalIsAccessTokenRefreshed_Key,
    ecpsExternalCurrentUser_Key
} from "../config.json";
import authService from "./authService";

function shouldRefresh() {
    let newDate = new Date();
    let currentDate = newDate.getTime();

    const tokenCreationJson = localStorage.getItem(ecpsExtTokenCreation_Key);
    var tokenCreation = tokenCreationJson ? new Date(JSON.parse(tokenCreationJson)).getTime() : currentDate;

    var minDiff = (currentDate - tokenCreation) / 60 / 1000; //in minutes
    var inProgress = localStorage.getItem(ecpsExtRefreshInProgress_Key);

    return (minDiff > 40 && (inProgress == null || inProgress !== "true"));
}

function isInRefreshing() {
    return localStorage.getItem(ecpsExtRefreshInProgress_Key) === "true";
}

function onRefreshing() {
    localStorage.setItem(ecpsExtRefreshInProgress_Key, "true");
}

function onRefreshed() {
    let newDate = new Date();
    let date = newDate.getTime();
    localStorage.setItem(ecpsExtTokenCreation_Key, JSON.stringify(date));
    localStorage.removeItem(ecpsExtRefreshInProgress_Key);
}

function onRefreshedError() {
    if (localStorage.getItem(ecpsExtRefreshInProgress_Key)) {
        localStorage.removeItem(ecpsExtRefreshInProgress_Key);
    }
}

axios.interceptors.request.use(async function (config) {
  //  config.headers.Authorization = 
  //      "Bearer " + localStorage.getItem(ecpsExternalAccessToken_Key);
  return config;
});

axios.interceptors.response.use((response) => {
    const noError = response.status === 200;
    if (noError && response.config.url.indexOf("SiteMaintenanceSetting") < 1
        && response.config.url.indexOf("/otp/") < 1
        && response.config.url.indexOf("refresh") < 1) {

        //var random = Math.floor(Math.random() * 20) * 100; // to eliminate parallel execution possibilities

        setTimeout(function () {
            if (shouldRefresh()) {
                onRefreshing();
                authService.refreshToken();
            }
        }, 2000);
    }

    return response;
}, (error) => {
        const expectedError = error.response && error.response.status === 401;
        if (!error.response || error.response.status !== 401) {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }

        var isLoginUrl = error.config.url.indexOf('otp/callback') >= 0;
        var isRefreshUrl = error.config.url.indexOf('refresh') > 0;

        if (expectedError && !isRefreshUrl && !isLoginUrl) {
            onRefreshing();
            return axios.post("/otp/refresh") 
            .then((response) => {
                const { otpIdToken, otpUser } = response.data;
                var otpUserJson = JSON.stringify(otpUser);
                localStorage.setItem(ecpsExternalIdToken_Key, otpIdToken);
                localStorage.setItem(ecpsExternalCurrentUser_Key, otpUserJson);

                localStorage.setItem(ecpsExternalIsAccessTokenRefreshed_Key, "true");
                onRefreshed();

                const config = error.config;
                return new Promise((resolve, reject) => {
                    axios.request(config).then(response => {
                        resolve(response);
                    }).catch((ex) => {
                        authService.logout();
                        onRefreshedError();
                        window.location = '/';
                    })
                });
            })
                .catch((ex) => {
                authService.logout();
                onRefreshedError();
                window.location = '/';
            });
        }
        return Promise.reject(error);
});

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    request: axios.request,
    delete: axios.delete,
    cancelToken: axios.CancelToken
};

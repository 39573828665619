import React, { Component } from 'react';
import { toast } from "react-toastify";
import Footer from "./Footer";
import LoginSection from './LoginSection';
import SolicitationList from './SolicitationList';
import Sidebar from './common/sidebar';
import _ from 'lodash';

import auth from "../services/authService";
import solicitationService from "../services/solicitationService";
import Error from "../components/Errors/Error";
import SolicitationType from "../enums/SolicitationType";

//import '../scss/main.scss';

export class Home extends Component {
    static displayName = Home.name;
    constructor(props) {
        super(props);
        this.state = {
            sols: [],
            searchInfo: null,
            defaultSearchInfo: null,
            loading: true,
            redirect: false,
            err: "Internal server error. Please contact support.",
            sortBy: "ClosingDate",
            currentCount: 0,
            currentPage: 1,
            resultsPerPage: 10,
            frontEndPagination: false,
            isUserLoggedIn: false,
            filterBaseSolicitation: null
        };
        this.filterSolicitations = this.filterSolicitations.bind(this);
        this.filterClick = this.filterClick.bind(this);
    }

    async componentDidMount() {
        this._mounted = true;
        const user = auth.getCurrentUser();

        if (user) {
            this.setState({ isUserLoggedIn: true, err: '' });
        } else {
            this.setState({ isUserLoggedIn: false, err: '' });
        }   
    }
    setDefaultSearchInfo = (searchInfo) => {
        this.setState({ searchInfo: searchInfo, defaultSearchInfo: searchInfo }, () => {
            this.populateSolicitations(searchInfo);
        });
    }
    handleSortBy = async (sortBy, stateChangeComplete) => {
        this.setState({ sortBy: sortBy }, stateChangeComplete);
        this.sortSolicitationList(sortBy);
        return true;
    }
    search = async (search) => {
        this.setState({ searchInfo: search, loading: true, redirect: false, source: "search" }, () => {
            this.populateSolicitations(search);
        });

    }
    async populateSolicitations(search) {
        try {

            const sortBy = this.state.sortBy;

            const { data } = await solicitationService.getSolicitations(search, sortBy);
            //console.log('data', data);
            
            this.setState({ sols: data.solicitations, loading: false, redirect: false, err: "" });
            this.sortSolicitationList(sortBy);

        } catch (ex) {
            //console.log(ex.response.status);
        }
    }
    async sortSolicitationList(sortBy) {
        var sortedSols = [];
        switch (sortBy) {
            case "ClosingDate":
                sortedSols = _.orderBy(this.state.sols, ["solicitationSortOrder", "solicitationResponseDeadline"], ["asc", "asc"]);
                this.setState({ sols: sortedSols, sortBy });
                break;
            case "Agency":
                sortedSols = _.orderBy(this.state.sols, "icid");
                this.setState({ sols: sortedSols, sortBy });
                break;
            case "Title":
                sortedSols = _.orderBy(this.state.sols, "solicitationTitle");
                this.setState({ sols: sortedSols, sortBy });
                break;
            default:
            // default code block
        }
    }

    filterClick(filterBaseSolicitation) {
        let filterResult = this.state.filterBaseSolicitation === filterBaseSolicitation ? null : filterBaseSolicitation;
        this.setState({ filterBaseSolicitation: filterResult });
    }

    //todo: ecps code to remove {Neha Manya}
    filterSolicitations(filterBaseSolicitation) {
        const solicitations = this.state.sols;
        return filterBaseSolicitation ? solicitations.filter(solicitation => solicitation.solicitationTypeId === SolicitationType.Base) : solicitations.filter(solicitation => solicitation.solicitationTypeId === SolicitationType.RTOP || solicitation.solicitationTypeId === SolicitationType.Other);  
    }

    static noSolicitationFoundLanguage() {
        return (<React.Fragment><div className="no-sol-lang">No Notice Found</div></React.Fragment>);
    }
    render() {
        const noSolFoundLanguage = this.state.loading ? '' : (<div className="no-sol-lang">No Notice Found</div>);
        var loader = (<div className="loader" style={{ position: "fixed", top: "40%", left: "45%" }}>Loading...</div>);

        const filterBaseSolicitation = this.state.filterBaseSolicitation;
        const solicitations = this.state.filterBaseSolicitation == null ? this.state.sols : this.filterSolicitations(filterBaseSolicitation);

        if (this.state.err.length > 0) {
            return (<Error />);
        } else {
            return (
                <React.Fragment>
                    {!this.state.isUserLoggedIn && <LoginSection />}
                    <div className="content not-logged-in">
                        <Sidebar search={this.search} setDefaultSearchInfo={this.setDefaultSearchInfo} onSortBy={this.handleSortBy}/>
                        <div className="main-content">
                            {
                                this.state.isUserLoggedIn ?
                                    (<div className="container-fluid solicitation-landing external">
                                        <div className="solicitation-landing section-heading headline">e-BidBoard RFQ's/NOI's Available for Electronic Submission</div>
                                        <div className="solicitation-header-container">
                                            <div className="solicitation-message">
                                                If you are interested in providing a capability statement/quote on one of the opportunities listed below, please follow the instructions in the linked PDF. If you have any questions about a specific opportunity, please contact the Purchasing Agent listed for that opportunity.
                                            </div>
                                        </div>
                                    </div>) :
                                    (<div className="container-fluid">
                                        <p className="site-warning">WARNING NOTICE: This is a U.S. Government computer system, which may be accessed and used only for authorized Government business by authorized personnel. Unauthorized access or use of this computer system may subject violators to criminal, civil and/or administrative action. All information on this computer system may be intercepted, recorded, read, copied and disclosed by and to authorized personnel for official purposes, including criminal investigations. Such information includes sensitive data encrypted to comply with confidentiality and privacy requirements. Access or use of this computer system by any person, whether authorized or unauthorized, constitutes consent to these terms. There is no right to privacy in this system.</p>
                                        <div className="solicitation-landing section-heading headline">e-BidBoard RFQ's/NOI's Available for Electronic Submission</div>
                                    </div>)

                            }
                            {this.state.loading ? loader : ''}
                            {solicitations.length > 0 ? <SolicitationList isUserLoggedIn={this.state.isUserLoggedIn} solList={solicitations} /> : noSolFoundLanguage}
                        </div>
                    </div>
                    <Footer/>
                </React.Fragment>

            );
        }
    }
}

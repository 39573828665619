import React, { Component } from 'react';
import Footer from "./Footer";
import Paper from '@material-ui/core/Paper';

class About extends Component {
    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content">
                        <div className="container-fluid">
                            <div className="section-heading">Electronic BidBoard</div>
                            <Paper className="content-container">
                                <React.Fragment>
                                    <p>NIAID has implemented a new process for electronic submission of quotes. The electronic BidBoard is one component of NIAID’s integrated, secure system for the electronic submission, capture, tracking and review of quotes. e-BodBoard was developed for the purposes of streamlining our quote submission and review processes, preparing for the eventual elimination of paper submissions, and reducing costs associated with storage of large paper files.</p>

                                    <div className="sub-header">Features:</div>
                                    <ul>
                                        <li>Upload files for electronic submission against a list of active RFQs</li>
                                        <li>View RFQ titles and submission deadlines</li>
                                        <li>Track quotes submission actions through "My Submission History"</li>
                                        <li>Revise a quote, which will over-write previous versions</li>
                                    </ul>
                                    <div className="sub-header">Benefits to the research community:</div>
                                    <ul>
                                        <li>Easy-to-locate active RFQs available for electronic submission</li>
                                        <li>Reduced costs for printing and mailing of paper files</li>
                                        <li>Eliminate security risk of paper processing and transportation time</li>
                                        <li>Real-time feedback on transmission success</li>
                                    </ul>
                                    <div className="sub-header">Benefits to the Office of Acquisitions:</div>
                                    <ul>
                                        <li>Single source for all quote submissions</li>
                                        <li>Reduced costs for storing large paper files</li>
                                        <li>Enhanced security with electronic files</li>
                                        <li>Reduced processing and review time</li>
                                    </ul>
                                    <div className="sub-header">e-Bidboard Acquisitions:</div>
                                    <ul>
                                        <li>Only available for acquisitions up to $25,000. </li>
                                        <li>Acquisitions above $25,000 please log in to  SAM.gov to view all RFQ/NOI's  and follow the instructions on submission of quotes .</li>
                                       </ul>
                                </React.Fragment>
                            </Paper>
                        </div>
                    </div >
                </div>
                <Footer />
            </div>
        )
    };
};

export default About;
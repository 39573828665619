import React, { Component } from "react";
import auth from "../services/authService";

const styleObj = {
    fontSize: "10pt",
    color: "#a12",
    textAlign: "center",
    paddingTop: "0",
    position: "absolute",
    top: "0",
    left: "620px"
}

export class BannerHeader extends Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        //try {
        //    const user = auth.getCurrentUser();
        //    console.log("user:", user);
        //    this.setState({ user: user });
        //} catch (ex) { }
    }

    render() {
        //const userName = this.state.user ? this.state.user.FirstName + " " + this.state.user.LastName + "[" + this.state.user.Email + "]": "";
        return ( 
            <div>
                <div className="top-header">
                    <div className="nih-details">
                        <a href="#" className="logo-nih">
                            <img src="images/logo-nih-mark.svg" className="logo-nih-mark" alt="National Institutes of Health (NIH) Logo" />
                            <img src="images/logo-nih-text-white.svg" className="logo-nih-niaid-text" alt="National Institute of Allergy and Infectious Diseases"/>
                        </a>
                        <div className="application-details">
                            <h1>e-BidBoard System</h1>
                        </div>
                    </div>
                </div>
            </div>           
        );
    }
}

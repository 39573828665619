import React, { Component } from "react";
// import auth from "../services/authService";
import preval from 'preval.macro'

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    // <img src="images/logo-hhs-gray.svg" alt="Logo HHS" className="logo-footer-hhs" />
    // <img src="images/logo-nih-mark-gray.svg" alt="Logo NIH" className="logo-footer-nih" />
    // <img src="images/logo-usagov-gray.svg" alt="Logo HHS" className="logo-footer-usagov" />

    render() {
        return (
            <div className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="footer-logos">
                                <a href="https://www.hhs.gov/" alt="www.hhs.gov" aria-label="visit HHS.gov" target="_blank" rel="noopener noreferrer"><img src="images/logo-hhs-gray.svg" className="logo-footer-hhs" alt="National Institutes of Health (NIH) Logo" /></a>
                                <a href="https://www.nih.gov/" alt="www.nih.gov" aria-label="visit NIH.gov" target="_blank" rel="noopener noreferrer"><img src="images/logo-nih-mark-gray.svg" alt="Logo NIH" className="logo-footer-nih" /></a>
                                <a href="https://www.usa.gov/" alt="www.usa.gov" aria-label="visit USA.gov" target="_blank" rel="noopener noreferrer"><img src="images/logo-usagov-gray.svg" alt="Logo HHS" className="logo-footer-usagov" /></a>
                            </div>
                            <div className="footer-text">
                                <div className="footer-content">
                                    <div className="req-links">
                                        <a href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html" alt="view Vulnerability Disclosure Policy" target="_blank" rel="noopener noreferrer">HHS Vulnerabillity Disclosure Policy </a>
                                        <a href="https://www.niaid.nih.gov" alt="visit NIAID.NIH.gov" target="_blank" rel="noopener noreferrer">NIAID.NIH.GOV</a>
                                        <a href="https://www.niaid.nih.gov/node/5228" alt="view the No Fear Act" target="_blank" rel="noopener noreferrer">No Fear Act</a>
                                        <a href="https://www.niaid.nih.gov/node/5229" alt="view the Privacy Policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                                        <a href="https://www.niaid.nih.gov/node/5225" alt="view the Freedom of Information Act" target="_blank" rel="noopener noreferrer">FOIA</a>
                                        <a href="https://inside.niaid.nih.gov/communications-media/accessibility-and-reasonable-accommodations" alt="view Accessibility Guidelines" target="_blank" rel="noopener noreferrer">Accessibility</a>
                                        <a href="mailto:niaide-bidboardadministrator@mail.nih.gov" alt="contact us" target="_blank" rel="noopener noreferrer">Contact Us</a>
                                    </div>
                                </div>
                                <div className="application-details">
                                    <div className="footer-title">electronic BidBoard</div>
                                    <div className="footer-version">2024.2.2</div>
                                    <div className="footer-version" hidden>Build Date: {preval`module.exports = new Date().toLocaleString();`}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;

import React, { Component, Fragment } from "react";
import DateFnsTzUtils from "./DateFnsTzUtils";
import DateTime from "./DateTimeConversionMethods";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ShowSidebar } from "./constants";

export class sidebar extends Component {

    constructor(props) {
        super(props);

        var defaultFields = this.getDefaultSearchFields();
        this.state = {
            keyword: defaultFields.keyword,
            closingDateStart: defaultFields.closingDateStart,
            closingDateEnd: defaultFields.closingDateEnd,
            sortBy: defaultFields.sortBy
        };
    }
    getMinDate = () => {
        var now = new Date();
        var nowUtc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
        return DateTime.getDateEt(nowUtc);
    }
    getDefaultSearchFields() {
        var now = new Date();
        var nowUtc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
        var nextYearUtc = Date.UTC(now.getUTCFullYear() + 1, now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());

        const defaultSearchFieldValues = {
            keyword: "",
            closingDateStart: DateTime.getDateEt(nowUtc),  
            closingDateEnd: DateTime.getDateEt(nextYearUtc), 
            sortBy: "ClosingDate"
        };
        return defaultSearchFieldValues;
    }

    async componentDidMount() {
        const isSidebarShown = !!localStorage.getItem(ShowSidebar);
        this.setState({ ShowSidebar: isSidebarShown !== ShowSidebar });
        this.props.setDefaultSearchInfo(this.getDefaultSearchFields());
    }

    // sideClosedClass = () => {
    //     if (!this.state.ShowSidebar || this.state.ShowSidebar == "false") {
    //         return "sidebar float-sidebar closed";
    //     }
    //     return "sidebar float-sidebar";
    // }

    searchInfo = (e) => {
        var searchInfo = {
            keyword: this.state.keyword,
            closingDateStart: this.state.closingDateStart,
            closingDateEnd: this.state.closingDateEnd
        };
        return searchInfo;
    }
    resetSearchFields(e) {
        var defaultFields = this.getDefaultSearchFields();
        this.setState({
            keyword: defaultFields.keyword,
            closingDateStart: defaultFields.closingDateStart,
            closingDateEnd: defaultFields.closingDateEnd,
            sortBy: defaultFields.sortBy
        });
        this.props.onSortBy(defaultFields.sortBy, this.stateChangeComplete);
    }
    
    stateChangeComplete = () => {
        var defaultFields = this.getDefaultSearchFields();
        this.props.search(defaultFields);
    }

    render() {
        const toggleSidebar = () => {
            this.setState({ ShowSidebar: this.state.ShowSidebar !== ShowSidebar });
            localStorage.setItem(ShowSidebar, this.state.ShowSidebar);

        };

        return (
            <div className={`sidebar float-sidebar ${this.state.ShowSidebar !== ShowSidebar ? 'closed' : ''}`}>
                <button className="btn sidebar-trigger float-trigger" onClick={() => { toggleSidebar() }}>
                    Filters
                    <img src="./images/icon-arrow-down-light.svg" alt="toggle arrow" style={{ position: "relative", zIndex: "-100" }} />
                </button>
                <div className="sidebar-content">
                    <form>
                        <div className="MuiFormControl-root MuiTextField-root container-sortby">
                            <label
                                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
                                data-shrink="true"
                                htmlFor="sortby"
                                id="sortby-label">
                                Sort By
                            </label>
                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                <select
                                    className="MuiSelect-root MuiSelect-select MuiInputBase-input MuiInput-input"
                                    value={this.state.sortBy}
                                    id="sortby" onChange={(e) => { this.props.onSortBy(e.target.value); this.setState({ sortBy: e.target.value }) }}>
                                    <option value="ClosingDate">Closing Date</option>
                                    <option value="Agency">Agency</option>
                                    <option value="Title">Title</option>
                                </select>
                                <img src="images/select-arrows.svg" alt="arrow dropdown" />
                            </div>
                        </div>
                        <div className="MuiFormControl-root MuiTextField-root">
                            <label
                                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required"
                                data-shrink="true"
                                htmlFor="keyword"
                                id="keyword-label">
                                Keyword
                            </label>
                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                <input
                                    aria-invalid="false"
                                    id="keyword"
                                    maxLength="50"
                                    type="text"
                                    className="MuiInputBase-input MuiInput-input"
                                    value={this.state.keyword}
                                    onChange={val => this.setState({ keyword: val.target.value })} />
                            </div>
                        </div>
                        <div className="MuiFormControl-root MuiTextField-root calendar-parent">
                            <Fragment>
                                <MuiPickersUtilsProvider utils={DateFnsTzUtils}>
                                    <Fragment>
                                        <label>Closing Date Range Start</label>
                                        <KeyboardDatePicker
                                            id="closing date range start"
                                            KeyboardButtonProps={{
                                                'aria-label': 'change closing date range start',
                                            }}
                                            value={this.state.closingDateStart}
                                            placeholder="MM/DD/YYYY"
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                            onChange={date => {
                                                this.setState({ closingDateStart:  date });
                                            }}
                                            minDate={this.getMinDate()}
                                            format="MM/dd/yyyy"
                                            showTodayButton
                                        />
                                    </Fragment>
                                </MuiPickersUtilsProvider>
                            </Fragment>
                            <Fragment>
                                <MuiPickersUtilsProvider utils={DateFnsTzUtils}>
                                    <Fragment>
                                        <label>Closing Date Range End</label>
                                        <KeyboardDatePicker
                                            id="closing date range end"
                                            KeyboardButtonProps={{
                                                'aria-label': 'change closing date range end'
                                            }}
                                            clearable
                                            value={this.state.closingDateEnd}
                                            placeholder="MM/DD/YYYY"
                                            InputProps={{ disableUnderline: true }}
                                            onChange={date => this.setState({ closingDateEnd: date })}
                                            minDate={this.getMinDate()}
                                            format="MM/dd/yyyy" />
                                    </Fragment>
                                </MuiPickersUtilsProvider>
                            </Fragment>
                        </div>
                    </form>
                    <div className="sidebar-actions">
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained btn-secondary"
                            tabIndex="0"
                            type="button"
                            onClick={() => this.resetSearchFields()}>
                            <span className="MuiButton-label">Reset</span>
                            <span className="MuiTouchRipple-root"></span>
                        </button>
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained btn-primary"
                            tabIndex="0"
                            type="button"
                            onClick={() => this.props.search(this.searchInfo())}>
                            <span className="MuiButton-label">Search</span>
                            <span className="MuiTouchRipple-root"></span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default sidebar;

import React, { Component } from "react";
import auth from "../services/authService";
import MainContainer from "./common/mainContainer";
import { datadogRum } from '@datadog/browser-rum';

class Logout extends Component {
    componentDidMount() {
    var logoutUrl = '';
    auth.logout()
        .then((response) => {
            datadogRum.removeUser();

            logoutUrl = response.data;
            //console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!')
            //console.log(logoutUrl);
            var url = window.location.href.match(/^.*\//);
          
            setTimeout(function () { window.location = logoutUrl; }, 1000);
        })
        .catch(() => {
            
            setTimeout(function () { window.location = "/"; }, 1000);
        });
    }

    render() {
        //return (<MainContainer><span>Logging out ... ...</span></MainContainer>);
        return (
            <React.Fragment>
                <div className="content" style={{ padding: "10px", backgroundColor: "#fff", width: "100%" }}> Logging out ... </div>
            </React.Fragment>
        );
    }
}

export default Logout;

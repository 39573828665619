import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";
import TextArea from "./textArea";
import DateInput from "./dateInput";

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

    validateProperty = ({ name, value }) => {
        const obj = { [name]: value };
        const schema = { [name]: this.schema[name] };

        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null;
    };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    //console.log("handle submit from ");
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    //console.log("errors: ", errors);
    if (errors) return;

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
      //console.log("Handle Change - level 2",input);
      const errors = Object.assign({}, this.state.errors);
      const errorMessage = this.validateProperty(input);
      if (errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];


      const data = Object.assign({}, this.state.data);
      data[input.name] = input.value;
      this.setState({ data, errors });
    };

    handleDateChange = (name,value) => {
        const input = { currentTarget:{ name, value }}
        //console.log("Input Formed:", input);
        this.handleChange(input);
    }

  renderButton(label) {
    return (
      <button disabled={this.validate()} className="btn btn-primary">
        {label}
      </button>
    );
  }

  renderSelect(name, label, options, isRequired=false) {
    const { data, errors } = this.state;
    return (
      <Select
            name={name}
            value={data[name]}
            label={label}
            options={options}
            onChange={this.handleChange}
            error={errors[name]}
            isRequired={isRequired}
      />
    );
  }

  renderInput(name, label,inputSize , type = "text", required=true) {
    const { data, errors } = this.state;
    return (
     <Input 
        inputSize={inputSize}
        type={type}
        name={name}
        value={data[name]}
        label={label}
        required={required}
        error={errors[name]}
        onChange={this.handleChange}
        onInput={this.handleChange}
        />
    );
    }

  renderTextArea(name, label) {
      const { data, errors } = this.state;
      return (
          <TextArea
              name={name}
              value={data[name]}
              label={label}
              error={errors[name]}
              onChange={this.handleChange}
              
          />
      );
    }

  renderDateInput(name, label, value) {
      const { data, errors } = this.state;
      return (
          <DateInput
                name={name}
                value={value}
                label={label}
                error={errors[name]}
                onChange={this.handleDateChange}
            />
      );
  }
}

export default Form;

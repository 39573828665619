// component-based dialog to be called
// same progress bar
// make sure user can't cancel by clicking anywhere else on the screen

// submission in progress
// standard dialog look with progress bar underneath the dialog header

// header: Submission in progress
// text: Your files are being uploaded. Please do not close this window or navigate away from this page.

// use variables for whether it's an upload, download etc

//   upload: {
//     header: 'Submission in progress',
//     mainText: 'Your files are being uploaded. Please do not close this window or navigate away from this page.
//   },
//   download: {
//     header: 'Download in progress',
//     mainText: 'Your files are being downloaded. Please do not close this window or navigate away from this page.
//   }

// */

/* LATER TODAY

  build form components
  insert form components into pages with forms
  work on table components with different classes to define the page

*/

import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';

function ProgressModal(props) {
  // const { title, children, openPopup, setOpenPopup } = props;
  let dialogText;

  const { onClose, open, selectedValue } = props;

  const handleClose = () => {
    onClose(selectedValue);
  }

  const progressBar = (
    <div className={`upload-bar-vertical-center upload-progress-modal`}>   
      <div className="upload-bar-container">
        <div className="upload-text">Uploading files</div>
        <div className="upload-bar">
          <div className="upload-progress" style={{width: `${props.loadedAmount}%`}}></div>
        </div>
        <div className="upload-percent">{props.loadedAmount}%</div>
      </div>
    </div>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth={true}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="progress-container"
      TransitionProps={{ role: 'main' }}
      >
          <DialogTitle id="progress-container"><h1>{props.title}</h1></DialogTitle>
        <DialogContent>
          <p>Your files are being uploaded. Please do not close this window or navigate away from this page.</p>
          {progressBar}
          <div className="dialog-footer float-right success-footer">
            {/*  /////  DO NOT DELETE! /////
             * {props.loadedAmount < 90 &&
             <button type="button" className="MuiButtonBase-root MuiButton-root btn btn-secondary" onClick={handleClose}>Cancel</button>
            }*/}
          </div>
        </DialogContent>
      </Dialog>
  )
}

ProgressModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired
}

export default ProgressModal;